<template>
  <div>
    <v-row class="mb-2">
      <v-col>
        <h3>Campaigns</h3>
      </v-col>
    </v-row>

    <ProgrammaticAppsTable />
  </div>
</template>

<script>
import ProgrammaticAppsTable from "../../components/Tables/ProgrammaticAppsTable.vue";

export default {
  components: {
    ProgrammaticAppsTable,
  },
};
</script>
